.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.btnWrapper {
  max-width: 300px;
}
