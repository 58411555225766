//EditTimeForm module depends on this module!
@import 'src/styles/variables';
.wrapper {
  width: 500px;
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.memberName {
  font-size: $fontSizeBodyS;
  color: #1b1b1b;
}

.trackerWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .dateWrapper {
    min-width: 140px;
    margin-right: 100px;
  }

  .timeWrapper {
    display: flex;
    > div:last-child {
      margin-left: 10px;
    }
  }
}

.billingWrapper {
  font-size: $fontSizeBodyS;
}

.controlWrapper {
  margin-left: auto;
  width: 100%;
  max-width: 240px;
  display: flex;

  button + button {
    margin-left: 10px;
  }
}

.jiraWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  
  > div:first-child {
    width: 100%;
  }
}

.jiraActionWrapper{
  display: flex;
  justify-content: end;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: 0 0 0 10px;
  
  > div > button, div:first-child {
    border-radius: 4px;
    width: 40px;
    height: 40px;
  }
  
  .jiraActionButtonWrapper:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

@media (max-width: 575px) {
  .wrapper {
    width: 100%;
    > div {
      margin-bottom: 10px;
    }
  }
}
